
import {
    computed, defineComponent, h, reactive,
} from 'vue';
import { FormDefinition, TableDefinition } from '@/types';
import TrailerTypeService from '@/services/TrailerTypeService';
import TrailerType from '@/domain/TrailerType';
import Screen from '@/components/layout/Screen.vue';
import coreStore from '@/store/CoreStore';
import DynamicForm from '@/components/form/DynamicForm.vue';
import ItemType from '@/domain/ItemType';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import { trailerTypeMetaData } from '@/meta-data/trailer';
import useUnitOfMeasure from '@/measurement/useUnitOfMeasure';
import UomBadge from '@/components/UomBadge.vue';
import MasterDataRouteTypes from '../../routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import router from '@/router';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    itemEdit: TrailerType;
    showForm: boolean;
    modalTitle: string;
    saving: boolean;
};

export default defineComponent({
    name: 'trailer-type-list',
    components: {
        BDropdown,
        BDropdownItem,
        BAdvancedTable,
        BButton,
        BModal,
        BSpinner,
        DynamicForm,
        Screen,
    },

    setup() {
        const trailerTypeService = new TrailerTypeService();
        const { configStore } = coreStore.getInstance();

        const { validateForm, validationResult, clearResults } = useValidator<TrailerType>('trailer-type');

        const state = reactive<State>({
            showForm: false,
            itemEdit: new TrailerType(),
            modalTitle: '',
            saving: false,
        });

        const useTrailerTypeLengthUom = trailerTypeMetaData.length?.uom ? useUnitOfMeasure(trailerTypeMetaData.length.uom) : undefined;
        const useTrailerTypeWidthUom = trailerTypeMetaData.width?.uom ? useUnitOfMeasure(trailerTypeMetaData.width.uom) : undefined;
        const useTrailerTypeHeightUom = trailerTypeMetaData.height?.uom ? useUnitOfMeasure(trailerTypeMetaData.height.uom) : undefined;
        const useTrailerTypeCubicUom = trailerTypeMetaData.cubicVolume?.uom ? useUnitOfMeasure(trailerTypeMetaData.cubicVolume.uom) : undefined;
        const useTrailerTypeMaxLoadCapacityUom = trailerTypeMetaData.maxLoadCapacity?.uom ? useUnitOfMeasure(trailerTypeMetaData.maxLoadCapacity.uom) : undefined;

        const table = computed(
            (): TableDefinition<TrailerType> => ({
                items: configStore.trailerTypes,
                key: 'trailerTypeList',
                name: getTitleCaseTranslation('core.domain.trailerTypes'),
                columnDefinition: [
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                        searchable: true,
                    },
                    {
                        key: 'shortName',
                        label: getTitleCaseTranslation('core.domain.shortName'),
                        searchable: true,
                    },
                    {
                        key: 'length',
                        label: getTitleCaseTranslation('core.domain.length'),
                        searchable: true,
                        uom: useTrailerTypeLengthUom,
                        headerBadge: () => h(UomBadge, {
                            uom: useTrailerTypeLengthUom,
                            onClick: useTrailerTypeLengthUom?.toggleUnitOfMeasure,
                        }),
                    },
                    {
                        key: 'width',
                        label: getTitleCaseTranslation('core.domain.width'),
                        searchable: true,
                        uom: useTrailerTypeWidthUom,
                        headerBadge: () => h(UomBadge, {
                            uom: useTrailerTypeWidthUom,
                            onClick: useTrailerTypeWidthUom?.toggleUnitOfMeasure,
                        }),
                    },
                    {
                        key: 'height',
                        label: getTitleCaseTranslation('core.domain.height'),
                        searchable: true,
                        uom: useTrailerTypeHeightUom,
                        headerBadge: () => h(UomBadge, {
                            uom: useTrailerTypeHeightUom,
                            onClick: useTrailerTypeHeightUom?.toggleUnitOfMeasure,
                        }),
                    },
                    {
                        key: 'cubicVolume',
                        label: getTitleCaseTranslation('core.domain.cubicVolume'),
                        searchable: true,
                        uom: useTrailerTypeCubicUom,
                        headerBadge: () => h(UomBadge, {
                            uom: useTrailerTypeCubicUom,
                            onClick: useTrailerTypeCubicUom?.toggleUnitOfMeasure,
                        }),
                    },
                    {
                        key: 'maxLoadCapacity',
                        label: getTitleCaseTranslation('core.domain.maxLoadCapacity'),
                        searchable: true,
                        uom: useTrailerTypeMaxLoadCapacityUom,
                        headerBadge: () => h(UomBadge, {
                            uom: useTrailerTypeMaxLoadCapacityUom,
                            onClick: useTrailerTypeMaxLoadCapacityUom?.toggleUnitOfMeasure,
                        }),
                    },
                    {
                        key: 'loadType',
                        label: getTitleCaseTranslation('core.domain.loadType'),
                        searchable: true,
                    },
                ],
            }),
        );

        const formDefinition: Array<FormDefinition<TrailerType>> = [
            {
                key: 'name',
                label: getTitleCaseTranslation('core.domain.name'),
                type: 'string',
                autofocus: true,
            },
            {
                key: 'shortName',
                label: getTitleCaseTranslation('core.domain.shortName'),
                type: 'string',
            },
            {
                key: 'length',
                label: getTitleCaseTranslation('core.domain.length'),
                type: useTrailerTypeLengthUom || 'number',
            },
            {
                key: 'width',
                label: getTitleCaseTranslation('core.domain.width'),
                type: useTrailerTypeWidthUom || 'number',
            },
            {
                key: 'height',
                label: getTitleCaseTranslation('core.domain.height'),
                type: useTrailerTypeHeightUom || 'number',
            },
            {
                key: 'cubicVolume',
                label: getTitleCaseTranslation('core.domain.cubicVolume'),
                type: useTrailerTypeCubicUom || 'number',
                disabled: true,
            },
            {
                key: 'maxLoadCapacity',
                label: getTitleCaseTranslation('core.domain.maxLoadCapacity'),
                type: useTrailerTypeMaxLoadCapacityUom || 'number',
            },
            {
                key: 'loadType',
                label: getTitleCaseTranslation('core.domain.loadType'),
                type: 'string',
            },
        ];

        function openAdd() {
            clearResults();
            state.itemEdit = new TrailerType();
            state.modalTitle = getTitleCaseTranslation('core.domain.newTrailerType');
            state.showForm = true;
        }

        function openEdit(item: ItemType) {
            clearResults();
            state.itemEdit = new TrailerType(item);
            state.modalTitle = getTitleCaseTranslation('core.domain.editTrailerType');
            state.showForm = true;
        }

        async function openTrailerTypesHistory(item: ItemType) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.TRAILER_TYPE, entityId: item.id },
            });
        }

        async function save() {
            validateForm(state.itemEdit);

            if (validationResult.isValid) {
                state.saving = true;

                if (state.itemEdit.id > 0) {
                    const response = await trailerTypeService.updateTrailerType(state.itemEdit);
                    if (response) {
                        state.showForm = false;
                    }
                } else {
                    const response = await trailerTypeService.addNewTrailerType(state.itemEdit);
                    if (response) {
                        state.showForm = false;
                    }
                }

                state.saving = false;
            }
        }

        return {
            table,
            formDefinition,
            state,
            getTitleCaseTranslation,
            openAdd,
            openEdit,
            openTrailerTypesHistory,
            save,
            validationResult,
        };
    },
});
