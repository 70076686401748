import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"margin-left":"-2px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_badge = _resolveComponent("b-badge")!

  return (_openBlock(), _createBlock(_component_b_badge, _mergeProps(_ctx.$props, _toHandlers(_ctx.uom?.hasMultipleOptions() ? { click: _ctx.handleClick } : {})), {
    default: _withCtx(() => [
      (_ctx.uom)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation(`core.uom.${_ctx.uom.displayedUnitOfMeasure.value}`)) + " ", 1),
            _createElementVNode("sup", _hoisted_2, _toDisplayString(_ctx.superScript), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}