import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_b_form_uom_input = _resolveComponent("b-form-uom-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createBlock(_component_b_row, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formDefinition, (definition) => {
        return (_openBlock(), _createBlock(_component_b_col, {
          key: definition.key,
          cols: "4",
          class: "form-col"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, 'input-' + definition.key, {}, () => [
              (definition.type === 'string')
                ? (_openBlock(), _createBlock(_component_text_input, {
                    key: definition.disabled,
                    modelValue: _ctx.modelValue[definition.key],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[definition.key]) = $event),
                    autofocus: definition.autofocus,
                    label: _ctx.getLabel(definition),
                    disabled: definition.disabled,
                    error: _ctx.validationResult.model[definition.key],
                    onInput: _ctx.handleInput
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "autofocus", "label", "disabled", "error", "onInput"]))
                : _createCommentVNode("", true),
              (definition.type === 'number')
                ? (_openBlock(), _createBlock(_component_number_input, {
                    key: definition.disabled,
                    modelValue: _ctx.modelValue[definition.key],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[definition.key]) = $event),
                    label: _ctx.getLabel(definition),
                    disabled: definition.disabled,
                    error: _ctx.validationResult.model[definition.key],
                    onInput: _ctx.handleInput
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disabled", "error", "onInput"]))
                : _createCommentVNode("", true),
              (definition.type === 'boolean')
                ? (_openBlock(), _createBlock(_component_checkbox_input, {
                    key: definition.disabled,
                    modelValue: _ctx.modelValue[definition.key],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[definition.key]) = $event),
                    label: _ctx.getLabel(definition),
                    disabled: definition.disabled,
                    error: _ctx.validationResult.model[definition.key],
                    onInput: _ctx.handleInput
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disabled", "error", "onInput"]))
                : _createCommentVNode("", true),
              (definition.type === 'date')
                ? (_openBlock(), _createBlock(_component_date_input, {
                    key: definition.disabled,
                    modelValue: _ctx.modelValue[definition.key],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[definition.key]) = $event),
                    label: _ctx.getLabel(definition),
                    disabled: definition.disabled,
                    error: "validationResult.model[definition.key]",
                    onInput: _ctx.handleInput
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disabled", "onInput"]))
                : _createCommentVNode("", true),
              (definition.type === 'array')
                ? (_openBlock(), _createBlock(_component_select_input, {
                    key: definition.disabled,
                    model: _ctx.modelValue[definition.key][definition.listProps.value],
                    options: definition.listProps.options,
                    value: definition.listProps.value,
                    text: definition.listProps.text,
                    label: _ctx.getLabel(definition),
                    disabled: definition.disabled,
                    error: "validationResult.model[definition.key]",
                    onChange: (val) => _ctx.value[definition.key] = val
                  }, null, 8, ["model", "options", "value", "text", "label", "disabled", "onChange"]))
                : _createCommentVNode("", true),
              (!!definition.type.uomMetaData)
                ? (_openBlock(), _createBlock(_component_b_form_uom_input, {
                    key: definition.disabled,
                    modelValue: _ctx.modelValue[definition.key],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[definition.key]) = $event),
                    label: _ctx.getLabel(definition),
                    disabled: definition.disabled,
                    uom: definition.type,
                    autofocus: definition.autofocus,
                    error: _ctx.validationResult.model[definition.key],
                    required: definition.required,
                    onInput: _ctx.handleInput
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disabled", "uom", "autofocus", "error", "required", "onInput"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 3
  }))
}