
import {
    defineComponent, onBeforeMount, onUpdated, PropType,
} from 'vue';
import { FormDefinition } from '@/types';
import { ValidationResult } from '@/validation/types';
import BFormUomInput from '@/components/bootstrap-library/BFormUomInput.vue';

export default defineComponent({
    name: 'dynamic-form',
    components: { BFormUomInput },
    props: {
        modelValue: {
            type: Object,
            default: () => {
            },
        },
        formDefinition: {
            type: Array as PropType<Array<FormDefinition<unknown>>>,
            default: () => [],
        },
        validationResult: {
            type: Object as PropType<ValidationResult<unknown>>,
        },
    },
    emits: ['update:modelValue', 'input'],
    setup(props, context) {
        onUpdated(() => {
            init();
        });

        onBeforeMount(() => {
            init();
        });

        // sometimes we toggle the v-model without unmounting/remounting => because of this, we also run this on onUpdated
        function init() {
            // state.formObj = props.modelValue
            checkValidFormDefinition();
        }

        function checkValidFormDefinition() {
            for (const def of props.formDefinition) {
                if (def.type === 'array') {
                    if (!def.listProps) throw new Error(`List props missing from ${def.key as string}`);
                }
            }
        }

        function formattedLabel(label: string): string {
            const result = label.replace(/([A-Z])/g, ' $1');
            return result.charAt(0)
                .toUpperCase() + result.slice(1);
        }

        function getLabel(def: FormDefinition<any>): string {
            let label = '';
            if (def.label) {
                label = def.label;
            } else {
                label = formattedLabel(def.key as string);
            }
            if (def.required) label += '*';
            return label;
        }

        function handleInput(e: any) {
            context.emit('input', props.modelValue);
            context.emit('update:modelValue', props.modelValue); // updates v-model
        }

        return {
            getLabel,
            handleInput,
        };
    },
});
