import { MetaData } from '@/meta-data/types';
import TrailerType from '@/domain/TrailerType';

export const trailerTypeMetaData: Partial<Record<keyof TrailerType, MetaData>> = {
    height: {
        uom: {
            type: 'length',
            measure: 'feet',
            spacial: 'linear',
        },
    },
    length: {
        uom: {
            type: 'length',
            measure: 'feet',
            spacial: 'linear',
        },
    },
    width: {
        uom: {
            type: 'length',
            measure: 'feet',
            spacial: 'linear',
        },
    },
    cubicVolume: {
        uom: {
            type: 'length',
            measure: 'feet',
            spacial: 'cubic',
        },
    },
    maxLoadCapacity: {
        uom: {
            type: 'weight',
            measure: 'pounds',
            spacial: 'linear',
        },
    },
};
