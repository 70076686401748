
import { defineComponent } from 'vue';
import TrailerTypeList from '@/modules/master-data/views/config/TrailerTypeList.vue';

export default defineComponent({
    name: 'trailer-type-config',
    components: { TrailerTypeList },

    setup() {
        return {

        };
    },

});
