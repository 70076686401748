
import { computed, defineComponent, PropType } from 'vue';
import BBadge from '@/components/bootstrap-library/BBadge.vue';
import { UseUnitOfMeasure } from '@/measurement/types';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'uom-badge',
    components: { BBadge },
    extends: BBadge,
    props: {
        uom: {
            type: Object as PropType<UseUnitOfMeasure>,
            default: () => undefined,
            required: false,
        },
    },
    emits: ['click'],
    setup(props, context) {
        function handleClick() {
            context.emit('click');
        }

        const superScript = computed((): '2' | '3' | null => {
            if (props.uom?.uomMetaData.spacial === 'square') return '2';
            if (props.uom?.uomMetaData.spacial === 'cubic') return '3';
            return null;
        });

        return {
            getTitleCaseTranslation,
            handleClick,
            superScript,
        };
    },
});
